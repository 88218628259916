//REGISTER
export const POST_REGISTER = "/auth/signup";

//LOGIN
export const POST_LOGIN = "/auth/signin";
export const POST_PASSWORD_FORGET = "/auth/forgot-password";

//PROFILE
export const POST_EDIT_PROFILE = "/post-jwt-profile";
export const GET_PROFILE = "/user";
