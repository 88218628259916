// src/Pages/Auth/Signup.tsx

import React, { useContext, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import Layout from '../../Layout';
import GoButton from '../../component/GoButton/GoButton';
import { postRegister } from '../../helpers/backend_helper';
import { RegisterDto } from '../../helpers/models/auth.model';

const Register: React.FC = () => {
  const [showNinForm, setShowNinForm] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<'success' | 'failure' | null>(null);
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
  const globalContext = useContext(GlobalContext) as GlobalContextType;

  // Move useFormik out of the conditional block
  const initialValues: RegisterDto = {
    title: '',
    firstName: '',
    lastName: '',
    no: '',
    road: '',
    city: '',
    state: '',
    country: '',
    telephone: '',
    nin: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Please Enter Title'),
    firstName: Yup.string().required('Please Enter First Name'),
    lastName: Yup.string().required('Please Enter lastName'),
    no: Yup.string().required('Please Enter House Number'),
    road: Yup.string().required('Please Enter Landmark'),
    city: Yup.string().required('Please Enter City'),
    state: Yup.string().required('Please Enter State'),
    country: Yup.string().required('Please Enter Country'),
    telephone: Yup.string().required('Please Enter Telephone'),
    email: Yup.string().email().required('Please Enter Email Address'),
    confirmEmail: Yup.string()
      .email()
      .oneOf([Yup.ref('email'), null], 'Email must match')
      .required('Please Confirm Your Email'),
    password: Yup.string().required('Please Enter Password'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please Confirm Your Password'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues, ...globalContext.registerUserData },
    validationSchema,
    onSubmit: (values: RegisterDto) => {
      globalContext.setRegisterUserData(values);
      setShowNinForm(true);
    },
  });

  if (!globalContext) {
    return null;
  }

  const { registerUserData, setRegisterUserData, showRegister, setShowRegister } = globalContext;

  const handleNinSubmit = async () => {
    const values: RegisterDto = { ...registerUserData, nin: formik.values.nin };
    try {
      const result = await postRegister(values);
      console.log('User registered:', result);
      setVerificationStatus('success');
      setTimeout(() => navigate('/dashboard'), 2000); // Redirect after 2 seconds
    } catch (error) {
      console.error('Registration failed:', error);
      setVerificationStatus('failure');
    }
  };

  const ninForm = (
    <>
      <h5 className="text-green">Please fill in your NIN information.</h5>
      <Form autoComplete="off" className="nin-form">
        <Form.Group className="mb-3 text-center">
          <Form.Label className="text-green w-100">NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Xxx xxx xxx xxx"
            name="nin"
            value={formik.values.nin}
            onChange={formik.handleChange}
            className="input-custom"
          />
        </Form.Group>
        <div className="mb-3 text-center">
          <p>Please type in your NIN to continue</p>
          <GoButton onClick={handleNinSubmit} />
        </div>
      </Form>
    </>
  );

  const successMessage = (
    <div className="verification-message text-center">
      <h2 className="text-green">Successful</h2>
      <p className="text-green">Your NIN has been verified</p>
      <p className="text-green">Your account has been created</p>
      <p className="text-green">You will be redirected to your account page</p>
      <div className="checkmark">&#10003;</div>
    </div>
  );

  const failureMessage = (
    <div className="verification-message text-center">
      <h2 className="text-danger">Unsuccessful</h2>
      <p className="text-danger">Your NIN could not be verified</p>
      <p className="text-danger">Please try again or contact support</p>
      <div className="crossmark">&#10007;</div>
    </div>
  );

  const closeRegister = () => {
    setShowRegister(false);
  };

  const formContent = (
    <>
      <h5 className="text-green">Please fill in all required information to register.</h5>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} className="register-form">
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Mr"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.title && formik.touched.title}
                className="input-custom"
              />
              {formik.errors.title && formik.touched.title ? (
                <Form.Control.Feedback type="invalid">{formik.errors.title}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.firstName && formik.touched.firstName}
                className="input-custom"
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">lastName</Form.Label>
              <Form.Control
                type="text"
                placeholder="lastName"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.lastName && formik.touched.lastName}
                className="input-custom"
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="House Number"
                name="no"
                value={formik.values.no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.no && formik.touched.no}
                className="input-custom"
              />
              {formik.errors.no && formik.touched.no ? (
                <Form.Control.Feedback type="invalid">{formik.errors.no}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Road</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="road"
                value={formik.values.road}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.road && formik.touched.road}
                className="input-custom"
              />
              {formik.errors.road && formik.touched.road ? (
                <Form.Control.Feedback type="invalid">{formik.errors.road}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.city && formik.touched.city}
                className="input-custom"
              />
              {formik.errors.city && formik.touched.city ? (
                <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.state && formik.touched.state}
                className="input-custom"
              />
              {formik.errors.state && formik.touched.state ? (
                <Form.Control.Feedback type="invalid">{formik.errors.state}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.country && formik.touched.country}
                className="input-custom"
              />
              {formik.errors.country && formik.touched.country ? (
                <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Telephone</Form.Label>
              <Form.Control
                type="text"
                placeholder="+234 8144069 XXX"
                name="telephone"
                value={formik.values.telephone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.telephone && formik.touched.telephone}
                className="input-custom"
              />
              {formik.errors.telephone && formik.touched.telephone ? (
                <Form.Control.Feedback type="invalid">{formik.errors.telephone}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@gmail.com"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email && formik.touched.email}
                className="input-custom"
              />
              {formik.errors.email && formik.touched.email ? (
                <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Confirm Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Confirm Email"
                name="confirmEmail"
                value={formik.values.confirmEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.confirmEmail && formik.touched.confirmEmail}
                className="input-custom"
              />
              {formik.errors.confirmEmail && formik.touched.confirmEmail ? (
                <Form.Control.Feedback type="invalid">{formik.errors.confirmEmail}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.password && formik.touched.password}
                className="input-custom"
              />
              {formik.errors.password && formik.touched.password ? (
                <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="text-green w-100">Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
                className="input-custom"
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                <Form.Control.Feedback type="invalid">{formik.errors.confirmPassword}</Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
        <div className="mb-3 text-center">
          <p>Please click "GO" to continue</p>
          <GoButton onClick={formik.handleSubmit as () => void} />
        </div>
      </Form>
    </>
  );

  return (
    <Layout>
      {isLargeScreen ? (
        <Modal
          contentClassName="bg-dark text-white px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={showRegister}
          size="xl"
          centered
          dialogClassName="modal-green-border"
        >
          <h1 className="text-capitalize text-center text-green">Register</h1>
          <button
            onClick={closeRegister}
            className="text-green position-absolute end-0 mx-3 btn btn-link"
          >
            <i className="bi bi-x display-3" />
          </button>
          <Col
            style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
            md={9}
            lg={12}
            xl={9}
            className="m-auto border border-primary rounded-4 p-2 px-md-3 py-md-3"
          >
            <Modal.Body>
              {verificationStatus === 'success' && successMessage}
              {verificationStatus === 'failure' && failureMessage}
              {!verificationStatus && (showNinForm ? ninForm : formContent)}
            </Modal.Body>
          </Col>
        </Modal>
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <h1 className="text-capitalize text-center text-green">Register</h1>
              {verificationStatus === 'success' && successMessage}
              {verificationStatus === 'failure' && failureMessage}
              {!verificationStatus && (showNinForm ? ninForm : formContent)}
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Register;
