import { APIClient } from "./api_helper";
import { IUserDto } from "./models/auth.model";
import { LoginDto } from "./models/auth.model";

import * as url from "./url_helper"

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postRegister = (data: any) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = async (data: LoginDto): Promise<IUserDto> => {
  try {
    const response: any = await api.create(url.POST_LOGIN, data);
    console.log('Full response:', response); // Log the full response for debugging

    if (response) {
      console.log('Response data:', response);
      console.log('Keys in response:', Object.keys(response));

      if (response.requestSuccessful) {
        const responseData = response.responseData;
        console.log('Response data.responseData:', responseData);

        if (responseData) {
          const { profile, accessToken, roles } = responseData;
          console.log('Profile:', profile);
          console.log('Access Token:', accessToken);
          console.log('Roles:', roles);

          const user = { ...profile, accessToken, roles };
          sessionStorage.setItem('authUser', JSON.stringify(user));
          return user;
        } else {
          console.error('No responseData found in response');
          throw new Error('No responseData found in response');
        }
      } else {
        console.error('Login failed:', response.message);
        throw new Error(response.message || 'Login failed');
      }
    } else {
      console.error('No response data found');
      throw new Error('No response data found');
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

// postForgetPwd
export const postForgetPwd = (data: any) => api.create(url.POST_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);

export const postProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
