import { Button, Col, Container, Row } from "react-bootstrap";
import Order from "./Order";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../context/GlobalContext";
import { IPurchasedTicket } from "../../../helpers/models/txticket_api.model";
import { useMediaQuery } from "react-responsive";

const Orders = () => {
  const navigate = useNavigate();
  const [currentTickets, setCurrentTicket] = useState<IPurchasedTicket[]>();

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const { lastPurchaseTicketList, ticketList, setPurchasedTickets } =
    useContext(GlobalContext) as GlobalContextType;


  const onClickTicket = (
    ticketList: IPurchasedTicket[],
    isLastPurchase: boolean,
  ) => {
    setPurchasedTickets(ticketList);
    if (!isLastPurchase) setCurrentTicket(ticketList);
  };

  const mobileDevice = isMediumScreen ? "border border-primary" : "border-0";
  const desktopDevice = isMediumScreen
    ? "border-0"
    : "border border-primary rounded-4";
  const hideButton = !isMediumScreen ? "d-none" : "d-flex";
  return (
    <Container
      style={{ minHeight: !isMediumScreen ? "450px" : "300px", gap: "40px" }}
    >
      <Row
        className={`${desktopDevice} `}
        style={{ minHeight: !isMediumScreen ? "390px" : "300px" }}
      >
        <Col md={12} lg={12} xl={12}>
          <div className="d-md-none d-block">
            <h1
              className="text-capitalize text-primary text-center pb-3 fs-26"
              style={{
                fontWeight: 600,
                marginTop: "10px",
                letterSpacing: "3.9px",
              }}
            >
              Purchase History
            </h1>
            <h1
              className="fs-16 text-white text-center"
              style={{
                fontWeight: 600,
                marginTop: "-10px",
                letterSpacing: "2.9px",
              }}
            >
              Your ticket has been sent <br /> to your email address.
            </h1>
          </div>

          <div
            className={`${mobileDevice} rounded-4 p-2`}
            style={{ minHeight: isMediumScreen ? "350px" : "10px" }}
          >
            <div className="d-flex justify-content-center text-center ">
              <h6
                className="fs-36 d-none d-md-block text-primary text-capitalize m-0 px-2 mb-2 text-center"
                style={{
                  letterSpacing: "2.1px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                New Ticket
                {lastPurchaseTicketList &&
                Object.keys(lastPurchaseTicketList || {}).length &&
                lastPurchaseTicketList.ticketlist.length ? (
                  <span>s</span>
                ) : null}
              </h6>
              {/* <h6
              className="fs-16 d-block d-md-none text-center text-primary text-capitalize m-0 px-2 mb-0"
              style={{ letterSpacing: "2.1px", fontWeight: 500 }}
            >
              New Ticket
              {lastPurchaseTicketList &&
              Object.keys(lastPurchaseTicketList || {}).length &&
              lastPurchaseTicketList.ticketlist.length ? (
                <span>s</span>
              ) : null}
            </h6> */}
            </div>

            {/* <OrderHeading /> */}
            {lastPurchaseTicketList &&
            Object.keys(lastPurchaseTicketList || {}).length ? (
              <div
                className="rounded-4 py-0"
                style={
                  {
                    // background: "linear-gradient(#000,hsla(0,0%,100%,.091))",
                  }
                }
              >
                <Order
                  maxHeight="400px"
                  // setPurchasedTickets={setPurchasedTickets}
                  setPurchasedTickets={onClickTicket}
                  ticketList={lastPurchaseTicketList.ticketlist}
                  currentTickets={currentTickets}
                  isLastPurchasedTicket
                />
              </div>
            ) : null}
              {ticketList.length && Object.keys(ticketList[0] || {}).length && (
              <div>
                <Order
                  maxHeight="220px"
                  // setPurchasedTickets={setPurchasedTickets}
                  setPurchasedTickets={onClickTicket}
                  currentTickets={currentTickets}
                  ticketList={ticketList}
                />
              </div>
            )}
          </div>

          {/* <Col 
            style={{ maxWidth: "300px" }}
            className="mx-auto d-flex justify-content-between mb-3 p-2"
          >
            <Button
              style={{ fontWeight: 700, maxWidth: "80px" }}
              variant="primary"
              className="btn-sm primary text-dark mx-2 rounded-4 border border-white fs-11"
              onClick={() => downloadTicket(isLastPurchasedTicket)}
              disabled={!Object.keys(lastPurchaseTicketList || {}).length}
            >
              Download Ticket
            </Button>
            <Button
              style={{ fontWeight: 700, maxWidth: "80px" }}
              variant="primary"
              disabled={!Object.keys(lastPurchaseTicketList || {}).length}
              onClick={() => {
                if (
                  lastPurchaseTicketList &&
                  Object.keys(lastPurchaseTicketList || {}).length
                )
                  setPurchasedTickets(lastPurchaseTicketList.ticketlist);
                navigate(
                  `/purchased-ticket/${lastPurchaseTicketList?.purchaseid}`
                );
              }}
              className="btn-sm primary text-dark mx-2 rounded-4 border border-white fs-11"
            >
              Show Ticket
            </Button>
          </Col> */}
          {/* <div className="mx-auto" style={{ maxWidth: "270px" }}>
            <p className="text-light-emphasis fs-11">
              Please note that your ticket has been sent to your email address
              as well.
            </p>
          </div> */}
        </Col>

        {/* <Col md={12} lg={6} xl={6}>
          <div
            className={`${mobileDevice}  rounded-4 p-2`}
            style={{
              minHeight: isMediumScreen ? "300px" : "10px",
              marginTop: isMediumScreen ? "" : "-40px",
            }}
          >
            <div className="d-flex justify-content-center">
              <h6
                className="fs-36 text-primary text-capitalize m-0 px-2 mb-2 d-md-block d-none"
                style={{
                  letterSpacing: "2.1px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Previous Tickets
              </h6>
            </div>

            <OrderHeading />

          
          </div>

          <Col
            style={{ maxWidth: "300px" }}
            className="mx-auto d-flex justify-content-between mb-3 p-2"
          >
            <Button
              style={{ fontWeight: 700, maxWidth: "80px" }}
              variant="primary"
              className="btn-sm primary text-dark mx-2 rounded-4 border border-white fs-11"
              onClick={() => downloadTicket()}
              disabled={!currentTickets?.length}
            >
              Download Ticket
            </Button>
            <Button
              disabled={!currentTickets?.length}
              style={{ fontWeight: 700, maxWidth: "80px" }}
              onClick={() =>
                !currentTickets?.length
                  ? toast.warning("Select your tickets")
                  : navigate(`/purchased-ticket/show-ticket`)
              }
              variant="primary"
              className="btn-sm primary text-dark mx-2 rounded-4 border border-white fs-11"
            >
              Show Ticket
            </Button>
          </Col>
        </Col> */}
        <p
          className="d-block d-md-none text-primary fs-13 text-center"
          style={{ fontWeight: 600, marginBottom: "-15px" }}
        >
          Please click on a purchase to see your ticket
        </p>
      </Row>
      <div
        className={`${hideButton} justify-content-center`}
        style={{ marginTop: "30px" }}
      >
        <Button
          className="fs-20 text-dark border border-white"
          style={{ fontWeight: 600, width: "180px" }}
          onClick={() => navigate(`/purchased-ticket/show-ticket`)}
        >
          Show ticket
        </Button>
      </div>
    </Container>
  );
};

export default Orders;
