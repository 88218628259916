import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Content from "./Content/Content";
import React from "react";
import SmallScreenTextsComponent from "./SmallScreen";

interface ITextsComponentProps {
  children?: React.ReactNode; // For reusability on buy page
  show: boolean;
  title: string;
  isEmpty: boolean;
  texts: string[];
}

const TextsComponent = (props: ITextsComponentProps) => {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const onHideHandler = () => {
    navigate("/");
  };

  if (isLargeScreen) {
    const contentClass =
      props.isEmpty && !isMediumScreen ? "mr-2" : "px-2 py-4";
    return (
      <Modal
        contentClassName={`backgroundDropdown ${contentClass} px-lg-5 rounded-4`}
        backdropClassName="bg-transparent"
        show={props.show}
        onHide={onHideHandler}
        size="xl"
        centered
        style={{ marginTop : isLargeScreen ? "30px" : ""}}
      >
        <h1 className="text-capitalize text-primary text-center">
          {props.title}
        </h1>
        <Button
          onClick={() => navigate("/")}
          variant="white"
          className="text-white position-absolute end-0 mx-3 top-0"
        >
          <i className="bi bi-x display-3" />
        </Button>
        <Content {...props} isLargeScreen />
      </Modal>
    );
  }

  // if (isEmpty && !isMediumScreen) {
  if (props.isEmpty) {
    const contentClass = props.isEmpty ? "border border-primary" : "";
    return (
      <Modal
        onHide={onHideHandler}
        contentClassName={`bg-dark mr-2 px-lg-5 rounded-4 ${contentClass}`}
        backdropClassName="bg-transparent"
        show={props.show}
        size="xl"
        centered={isMediumScreen}
      >
        <SmallScreenTextsComponent {...props} />
      </Modal>
    );
  }

  return <SmallScreenTextsComponent {...props} />;
};

export default TextsComponent;
