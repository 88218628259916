// import { boxShadow } from "html2canvas/dist/types/css/property-descriptors/box-shadow";
import moment from "moment";
import React, { Fragment, useState } from "react";
import Flatpickr from "react-flatpickr";

interface ISelectDateDropdownProps {
  selectedDate: string;
  handleDateSelection: (event: any) => void;
}

const SelectDateDropdown = ({
  selectedDate,
  handleDateSelection,
}: ISelectDateDropdownProps) => {
  const [state, setState] = useState(selectedDate);

  const onChange = (dateEvent: any) => {
    if (Array.isArray(dateEvent)) {
      const date = dateEvent[0];
      setState(date);
      handleDateSelection(date);
    } else {
      setState(dateEvent);
      handleDateSelection(dateEvent);
    }
    const doc = document.querySelector(
      '.flatpicker-custom-selector[readonly="readonly"]',
    );
    if (doc) doc.classList.remove("flatpicker-custom-selector-inactive");
    if (doc) doc.classList.add("selectionDrop");
    if (doc) doc.classList.add("fs-24");
  };

  return (
    <Fragment>
      <Flatpickr
        name="date-picker"
        id="date-field"
        className={`form-control text-uppercase ${
          selectedDate
            ? "selectionDrop"
            : "bg-primary flatpicker-custom-selector-inactive"
        } text-center flatpicker-custom-selector`}
        placeholder="Date"
        readOnly
        options={{
          disableMobile: "true",
          altInput: true,
          // dateFormat: "YYYY-MM-DD",
          dateFormat: "DD/MM/YYYY",
          altFormat: "DD/MM/YYYY",
          allowInput: false,
          minDate: "today",
          locale: {
            firstDayOfWeek: 1, // start week on Monday
          },
          parseDate: (dateString: string, format: string) => {
            console.log("dateString", dateString);
            return moment(new Date("2024-05-03"), format, true).toDate();
          },
          formatDate: (date: string, format: string, locale: any) => {
            // locale can also be used
            return moment(date).format(format);
          },
        }}
        onClose={(selectedDates: any, dateStr: any, instance: any) => {
          if (dateStr) {
            instance.altInput.classList.remove("bg-primary");
            instance.altInput.classList.add("bg-dark");
          } else {
            instance.altInput.classList.remove("bg-dark");
            instance.altInput.classList.add("bg-primary");
          }
        }}
        onChange={onChange}
        value={Array.isArray(state) ? state[0] : state}
      />
    </Fragment>
  );
};

export default SelectDateDropdown;
