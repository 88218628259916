import { Button } from "react-bootstrap";
import { IShoppingBasketData } from "../../../../helpers/models/txticket_api.model";
import ApiInstance from "../../../../helpers/txticket_api";
import moment from "moment";
import { toast } from "react-toastify";
import React, { useState } from "react";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../../context/GlobalContext";
import { RemoveItemPrompt } from "../../../../component/RemoveItemPrompt/RemoveItemPrompt";

interface OrderItemProps {
  shoppingCartBasket: IShoppingBasketData;
}

const OrderItem = ({ shoppingCartBasket }: OrderItemProps) => {
  const [hoverState, setHoveredState] = useState<IShoppingBasketData>();
  // const [state, setState] = useState<IShoppingBasketData>();
  const [isOpened, setIsOpened] = useState(false);

  const { removeFromShoppingCartBasket } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  const onDeleteTicket = () => {
    setIsOpened(false);
    ApiInstance.del_ticket(shoppingCartBasket.biunique, () => {
      toast.success("Ticket deleted successfully", { autoClose: 2000 });
      removeFromShoppingCartBasket(shoppingCartBasket.biunique);
    });
  };

  // const onClick = () => {
  //   setState(shoppingCartBasket);
  // };

  // const onBlur = () => {
  //   setState(undefined);
  // };

  const onMouseEnter = () => {
    setHoveredState(shoppingCartBasket);
  };

  const onMouseLeave = () => {
    setHoveredState(undefined);
  };

  const isHoverState = () => {
    if (hoverState) {
      return hoverState.biunique === shoppingCartBasket.biunique;
    }
  };

  // const isFocusState = () => {
  //   if (state) {
  //     return state.biunique === shoppingCartBasket.biunique;
  //   }
  // };

  return (
    // If this div is causing alignment problem in future in some browsers
    // would suggested to be moved up to wrap the table itself and move up the map function
    <React.Fragment>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        // onBlur={onBlur}
        // onClick={onClick}
        tabIndex={1}
        className={`mb-1 table-border-pre-active rounded-4 ${
          isHoverState() ? "border-primary" : ""
        }`}
      >
        <tr className="mb-0 bg-transparent border-0">
          <td
            className="bg-transparent text-white border-0 fs-13 pt-0 text-white"
            style={{ width: "46px" }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-0 fs-16 text-white">{shoppingCartBasket.count}</p>
              <Button
                onClick={() => setIsOpened(true)}
                className="btn-sm bg-transparent btn-ghost-primary text-white"
              >
                <i
                  style={{ transform: "scale(2)" }}
                  className="bi bi-trash-fill fs-20"
                />
              </Button>
            </div>
          </td>
          <td className="bg-transparent text-white border-0 fs-13 td-wrap text-start">
            <p className="fs-14 mb-0 text-white">
              {/* {selectedStations.from} -&gt; {selectedStations.to} */}
              {ApiInstance.get_station_name(
                shoppingCartBasket.route.stiddep,
              )}{" "}
              -&gt;{" "}
              {ApiInstance.get_station_name(shoppingCartBasket.route.stidarr)}
            </p>
            <p className="mb-1">
              <span>
                {moment(
                  new Date(shoppingCartBasket.route.leg[0].lxdate),
                ).format("dddd")}
                ,{" "}
                {moment(
                  new Date(shoppingCartBasket.route.leg[0].lxdate),
                ).format("D")}
                <sup>
                  {moment(new Date(shoppingCartBasket.route.leg[0].lxdate))
                    .format("Do")
                    .replace(/\d+/g, "")}
                </sup>{" "}
                {moment(
                  new Date(shoppingCartBasket.route.leg[0].lxdate),
                ).format("MMMM")}{" "}
                {moment(
                  new Date(shoppingCartBasket.route.leg[0].lxdate),
                ).format("YYYY")}
              </span>
              {", "}
              {moment(
                shoppingCartBasket.route.leg[0].tod_dep,
                "HH:mm:ss",
              ).format("hh:mm A")}
              <span>-</span>{" "}
              {moment(
                shoppingCartBasket.route.leg[0].tod_arr,
                "HH:mm:ss",
              ).format("hh:mm A")}{" "}
              Train No. {shoppingCartBasket.route.leg[0].lxid}.
              <strong className="text-white"> Free Seating.</strong>
            </p>
            <p className="text-white" style={{ marginBottom: "-5px" }}>
              <strong>
                Naira{" "}
                {(shoppingCartBasket.centprice / 100 || 0).toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  },
                )}
              </strong>
            </p>
          </td>
        </tr>
      </div>
      <RemoveItemPrompt
        onClose={() => setIsOpened(false)}
        onAcceptPrompt={onDeleteTicket}
        isOpen={isOpened}
        // title="Continue Ticket Removal"
        title="Do you really want to delete a ticket?"
        text="Do you really want to delete a ticket"
      />
    </React.Fragment>
  );
};

export default OrderItem;
