import { Button, Table } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const OrderHeading = () => {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  return (
    <Table className="align-middle table-nowrap mb-0"  style={{ paddingBottom: isMediumScreen? "": "-15px"}}>
      <thead>
        <tr>
          <th className="bg-transparent text-primary border-0" scope="col" style={{ width: '46px'}}>
            Qty
          </th>
          <th className="bg-transparent text-primary border-0 text-start" scope="col">
            Booking
          </th>
        </tr>
      </thead>
      <tbody className="text-white position-absolute opacity-0">
        <tr>
          <td className="bg-dark text-white border-0 fs-13 pt-0">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-0 fs-14">2</p>
              <Button className="btn-sm bg-transparent btn-ghost-primary text-primary">
                <i
                  style={{ transform: "scale(1.5)" }}
                  className="bx bxs-trash-alt"
                />
              </Button>
            </div>
          </td>
          <td className="bg-dark text-white border-0 fs-13">
            <p className="fs-14 mb-2 text-white">
              {/* {selectedStations.from} -&gt; {selectedStations.to} */}
              Aba -&gt; Lagos
            </p>
            <p>
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00 
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00
              Tuesday, May 7, 2024, Train No. 264, Free Seating, Price ₦ 800.00
            </p>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OrderHeading;
