import React, { ReactNode, useEffect } from "react";

import Navigation from "../component/Navigation/Navigation";
import { ContinuePurchase } from "../component/ContinuePurchase/ContinuePurchase";
import Footer from "../component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Heading from "../component/Heading/Heading";
import { useMediaQuery } from "react-responsive";

interface LayoutProps {
  children: ReactNode;
}
const Layout = (props: LayoutProps) => {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 868px)",
  })

  const isSmallScreenBelow = useMediaQuery({
    query: "(max-width: 768px)",
  });
 

  const toggleBodyClass = () => {
    const header = document.querySelector("#page-topbar");
    if (window.scrollY < 20) {
      if (header) header.classList.remove("bg-dark");
    } else {
      if (header) header.classList.add("bg-dark");
    }
  };

  useEffect(() => {
    if (isSmallScreenBelow) {
      window.addEventListener("scroll", toggleBodyClass);
      toggleBodyClass();
      return () => window.removeEventListener("scroll", toggleBodyClass);
    }
  }, [isSmallScreenBelow]);

  const rowClass = isMediumScreen ? "home-container" : "";
  // const contentClass = isMediumScreen ? "content h-auto" : "mt-3";
  const contentClass = isMediumScreen ? "" : "mt-3";
  const contentClassDesktop = isLargeScreen ? "content" : "mt-3";
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Navigation />
        <div className="main-content">
          <div className="page-content d-print-none" style={{ height:"100%"}}>
            <Container className="my-auto overflow-hidden page-inner-content">
              <Row className={`${rowClass}  align-items-center d-flex`}>
                <Heading />
                <Col md={12} lg={6}>
                  <Col md={12} lg={9} xl={10} xxl={10} className="m-auto">
                    <div className={`${contentClass} ${contentClassDesktop} child-container w-100`}>
                      {props.children}
                    </div>
                  </Col>
                </Col>
              </Row>
            </Container>
            <Footer />
            <ContinuePurchase />
            <ToastContainer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
