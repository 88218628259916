import React from "react";
import { Dropdown } from "react-bootstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

interface ISelectLocationDropdownProps {
  stations: string[];
  selectedStations: { from: string; to: string };
  handleStationSelection: (selected: { [key: string]: string }) => void;
  text: "FROM" | "TO";
}

const SelectLocationDropdown = ({
  text,
  stations,
  selectedStations,
  handleStationSelection,
}: ISelectLocationDropdownProps) => {
  const onSelect = (selected: string) => {
    handleStationSelection({ [text.toLowerCase()]: selected });
  };

  const filterSelected = () => {
    const locationType = text === "FROM" ? "to" : "from";
    const selectedStation = selectedStations[`${locationType}`];
    return stations.filter((station: string) => station !== selectedStation);
  };

  const getValue = () => {
    const locationType = text === "FROM" ? "from" : "to";
    return selectedStations[`${locationType}`];
  };
  const loadingDisplay =  !stations.length ? "loading-display" : "not-loading-display"
  return (
    <React.Fragment>
      <Dropdown
        className="dropdown topbar-head-dropdown ms-1 main-menu-dropdown-head"
        id="notificationDropdown"
      >
        <Dropdown.Toggle
          id="notification"
          type="button"
          className={`${loadingDisplay} btn btn-topbar btn-ghost-dark arrow-none overflow-hidden ${
            getValue() ? "selectionDrop text-white fs-24" : "bg-primary text-black"
           
          } btn-lg w-100`}
          style={{
            fontWeight:stations ? 600 : "",
            padding: 0,
            height: "80%",
            marginTop: "20px",
            marginBottom: "20px",
            border: "2px solid white",
            boxShadow: "2px 2px 10px black",
          }}
        >
          {!stations.length ? "loading station..." : getValue() || text}
        </Dropdown.Toggle>
        <Dropdown.Menu
          onSelect={(e) => console.log(e)}
          variant="primary"
          className="dropdown-menu-lg dropdown-menu-xs dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          style={{ border: " 1px solid #00f600", boxShadow: "2px 2px 10px black", }}
        >
          <div className="dropdown-head rounded-top bg-dark">
            <div className="pt-2">
              <SimpleBar style={{ maxHeight: "200px" }} className="pe-2">
                {filterSelected().map((station) => (
                  <Dropdown.Item
                    key={station}
                    onClick={() => onSelect(station)}
                    className={`text-reset notification-item d-block dropdown-item dropdown-item-custom position-relative ${
                      getValue() === station ? "active" : ""
                    }`}
                  >
                    <h6 className="mt-0 fs-20 lh-base text-primary" style={{ marginBottom: "-5px"}}>
                      {station}
                    </h6>
                  </Dropdown.Item>
                ))}
              </SimpleBar>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default SelectLocationDropdown;
