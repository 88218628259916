// src/Pages/Auth/ForgotPassword.tsx

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import Layout from '../../Layout';
import GoButton from '../../component/GoButton/GoButton';
import { postForgetPwd } from '../../helpers/backend_helper'; // Adjust the import path as necessary

const Forgetpwd: React.FC = () => {
  const navigate = useNavigate();
  const isLargeScreenBelow = useMediaQuery({ query: '(min-width: 992px)' });

  const globalContext = useContext(GlobalContext) as GlobalContextType;
  if (!globalContext) {
    return null;
  }

  const { showForgotpwd, setShowForgotpwd } = globalContext;

  const [userEmail, setUserEmail] = useState<string>('');
  const [error, setErrorState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [submissionStatus, setSubmissionStatus] = useState<'success' | 'failure' | null>(null);

  useEffect(() => {
    if (userEmail.trim() !== '') {
      setErrorState(false);
      setErrorMessage('');
    }
  }, [userEmail]);

  const closeForgotPwd = () => {
    setShowForgotpwd(false);
  };

  const handleSend = async () => {
    if (userEmail.trim() === '') {
      setErrorState(true);
      setErrorMessage('Email is required');
    } else {
      try {
        await postForgetPwd({ email: userEmail });
        setSuccessMessage('Password reset link has been sent to your email.');
        setErrorState(false);
        setErrorMessage('');
        setSubmissionStatus('success');
        setTimeout(() => {
          setShowForgotpwd(false);
          navigate('/signin');
        }, 3000);
      } catch (error) {
        setErrorState(true);
        setErrorMessage('Failed to send reset link. Please check your email and try again.');
        setSubmissionStatus('failure');
      }
    }
  };

  const renderForm = () => (
    <>
      <h5 className="text-green">Please type in your email</h5>
      <Form.Group className="mb-3 text-center">
        <Form.Label className="text-green w-100">Please type your email.</Form.Label>
        <Form.Control
          id="input1"
          type="email"
          value={userEmail}
          placeholder="example@gmail.com"
          onChange={(e) => setUserEmail(e.target.value)}
          className="bg-black text-white mx-auto w-75"
        />
        {error && userEmail === '' && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>
      {errorMessage && (
        <div className="text-danger text-center mb-3">
          {errorMessage}
        </div>
      )}
      <div className="mb-3 text-center">
        <p>Please click "GO" to continue</p>
        <GoButton onClick={handleSend} />
      </div>
      <div className="text-center">
        <a href="/register" className="text-green">Please click here if you are not a registered user yet.</a>
        <br />
        <a href="/login" className="text-green">Please click here if you have already have an account .</a>
      </div>
    </>
  );

  const successNotification = (
    <div className="verification-message text-center">
      <h2 className="text-green">Successful</h2>
      <p className="text-green">Password reset link has been sent to your email.</p>
      <p className="text-green">You will be redirected to the sign-in page shortly.</p>
      <div className="checkmark">&#10003;</div>
    </div>
  );

  const failureNotification = (
    <div className="verification-message text-center">
      <h2 className="text-danger">Unsuccessful</h2>
      <p className="text-danger">Failed to send reset link. Please check your email and try again.</p>
      <div className="crossmark">&#10007;</div>
    </div>
  );

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="bg-dark text-white px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={showForgotpwd}
          size="xl"
          centered
          dialogClassName="modal-green-border"
        >
          <h1 className="text-capitalize text-center text-green">Forgot Password</h1>
          <button
            onClick={closeForgotPwd}
            className="text-green position-absolute end-0 mx-3 btn btn-link"
          >
            <i className="bi bi-x display-3" />
          </button>
          <Col
            style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
            md={9}
            lg={12}
            xl={9}
            className="m-auto border border-primary rounded-4 p-2 px-md-3 py-md-3"
          >
            <Modal.Body>
              {submissionStatus === 'success' && successNotification}
              {submissionStatus === 'failure' && failureNotification}
              {!submissionStatus && renderForm()}
            </Modal.Body>
          </Col>
        </Modal>
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <h1 className="text-capitalize text-center text-green">Forgot Password</h1>
              {submissionStatus === 'success' && successNotification}
              {submissionStatus === 'failure' && failureNotification}
              {!submissionStatus && renderForm()}
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default Forgetpwd;
